.App {
  text-align: center;
  background-color:#95d2f5;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  max-width: max-content;
}
.headshot {
  height: 20%;
  width: 20%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.App h1 {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 50px;
}
.App p {
  font-size: 30px;
  padding-bottom: 20%;
}

.fade {
  max-width: 90vh;
}
