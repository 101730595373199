.container {
    margin-top: 20px;
    background-color: #95d2f5;
    height: 85vh;
       
}

.smartscheduler {
    max-height: 65vh;
    max-width: 120vh;
  }
.sentiment {
    max-height: 80vh;
    max-width: 115vh;
}
h3 {
    color: black;
}
p {
    color: white;
    font-size: larger;
    margin: 0;
}
.caption {
    background: rgba(157, 159, 161);
    top: auto;
    bottom: 10%;
}
.item {
    height: 80vh;
}